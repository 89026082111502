import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>Take a look at some of the work we've done!</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <div className="portfolio-item">
                      <div className="hover-bg">
                        <a href={d.largeImage} title="" data-lightbox-gallery="gallery1">
                          <img
                            src={d.smallImage}
                            className="img-responsive"
                            alt="Project Title"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
