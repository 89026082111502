import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{
      padding: "20px 0",
      minHeight: "100px",
      backgroundColor: "#000000"
    }}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            style={{
              marginTop: "25px"  // Adjusted for bigger navbar
            }}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img 
              src="./img/image.png" 
              alt="Robsons BodyWorks" 
              style={{
                height: "120px",  // Much bigger logo
                width: "auto",
                marginTop: "-45px"  // Adjust vertical alignment
              }}
            />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right" style={{
            marginTop: "15px"  // Adjust menu items vertical alignment
          }}>
            <li>
              <a href="#about" className="page-scroll" style={{
                fontSize: "18px",  // Bigger font size
                padding: "15px 25px"  // More spacing between items
              }}>
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll" style={{
                fontSize: "18px",
                padding: "15px 25px"
              }}>
                Services
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll" style={{
                fontSize: "18px",
                padding: "15px 25px"
              }}>
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll" style={{
                fontSize: "18px",
                padding: "15px 25px"
              }}>
                Reviews
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" style={{
                fontSize: "18px",
                padding: "15px 25px"
              }}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
